(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("rxjs"), require("rxjs/operators"), require("parse5"));
	else if(typeof define === 'function' && define.amd)
		define("kontentDelivery", ["rxjs", "rxjs/operators", "parse5"], factory);
	else if(typeof exports === 'object')
		exports["kontentDelivery"] = factory(require("rxjs"), require("rxjs/operators"), require("parse5"));
	else
		root["kontentDelivery"] = factory(root["rxjs"], root["rxjs"]["operators"], root["parse5"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__511__, __WEBPACK_EXTERNAL_MODULE__2308__, __WEBPACK_EXTERNAL_MODULE__9395__) {
return 